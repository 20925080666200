<template>
  <CyHeader
    v-if="stack"
    :loading="loading">
    <template #avatar>
      <CyStackAvatar
        :size="32"
        :stack="stack"
        class="mr-1"
        data-cy="stack-avatar"/>
    </template>
    <template #title>
      {{ stack.name }}
      <CyTag
        variant="default"
        class="ml-4"
        :icon-before="isPublic ? 'public' : 'lock'">
        {{ $t(isPublic ? 'public' : 'private') }}
      </CyTag>
    </template>
    <template #description>
      {{ stack.description }}
    </template>
    <template #actions>
      <portal-target name="header-actions"/>
    </template>
    <template #metadata>
      <CyHeaderMetadata
        icon="domain"
        :label="stack.author"/>
      <CyHeaderMetadata
        v-if="stack.trusted"
        icon="verified_user"
        :label="$t('trusted')"/>
      <CyHeaderMetadata
        v-if="stack.quota_enabled"
        icon="data_usage"
        :label="$t('quotas.requiresQuota')"/>
    </template>
  </CyHeader>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CyHeader from '@/components/header.vue'
import CyHeaderMetadata from '@/components/header/metadata.vue'
import CyStackAvatar from '@/components/stack-avatar.vue'

export default {
  name: 'CyHeaderVariantStack',
  components: {
    CyHeader,
    CyHeaderMetadata,
    CyStackAvatar,
  },
  computed: {
    ...mapState('organization/stack', {
      loading: (state) => state.fetchInProgress.stack,
    }),
    ...mapGetters('organization/stack', [
      'stack',
    ]),
    isPublic () {
      return this.stack.status === 'public'
    },
  },
  i18n: {
    messages: {
      en: {
        private: 'Private',
        public: 'Public',
        trusted: 'Trusted',
      },
      es: {
        private: 'Privada',
        public: 'Pública',
        trusted: 'De confianza',
      },
      fr: {
        private: 'Privée',
        public: 'Publique',
        trusted: 'De confiance',
      },
    },
  },
}
</script>
